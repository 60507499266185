import { useEffect, useState } from "react";
import { IonButton, IonCol, IonContent, IonGrid, IonLoading, IonPage, IonRow } from '@ionic/react';
//img
import Logo from "../../assets/logo/logo.svg";
//css
import './login.css';
//components
import Footer from '../../components/Footer/Footer';

import { UserDb } from "../../Models/UserDb";
import { AuthenticationResult, EndSessionRequest, PublicClientApplication } from "@azure/msal-browser";
import { MSAL_CONFIG } from "../../components/AzureAuthentication/azure-authentication-config";
import { base64Encoding } from "../../utiles";
import { useEntityUser } from "../../store/User";
import { requestPostOptions } from "../../service";
const BaseUrlApi: string = process.env.REACT_APP_BASE_URL_API;

// const ua = window.navigator.userAgent;
// const msie = ua.indexOf("MSIE ");
// const msie11 = ua.indexOf("Trident/");
// const isIE = msie > 0 || msie11 > 0;

const BaseUrl: string = process.env.REACT_APP_BASE_URL;

export let myMSALObj = {} as PublicClientApplication;

const Login: React.FC = () => {
    const [showLoading, setShowLoading] = useState(false);
    const { user, setUser, resetUser } = useEntityUser();

    useEffect(() => {
        const authRedirectCallBack = (response: AuthenticationResult | null): void => {
            if (response !== null) {
                setShowLoading(true);
                fetch(`${BaseUrlApi}/Auth?mail=${response.account.username}`, {
                    method: "GET",
                    headers: {
                        'Authorization': 'Basic ' + base64Encoding(process.env.REACT_APP_API_AUTH),
                        'Content-Type': 'application/json',
                        "Access-Control-Allow-Origin": "*"
                    },
                    credentials: 'include'
                })
                    .then(res => res.json())
                    .then((result: UserDb) => {
                        // sessionStorage.setItem("loggedUser", JSON.stringify(result))
                        setUser(result);
                        onAuthenticated(result);
                    })
                    .catch((err) => {
                        onFailed();
                        console.log(err);
                    });
            }
            else {
                // pulizia di eventuali cookies di un precedente login non completato
                var cookies = document.cookie.split(";");
                for (var i = 0; i < cookies.length; i++) {
                    var cookie = cookies[i];
                    var eqPos = cookie.indexOf("=");
                    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
            }
        }

        myMSALObj = new PublicClientApplication(MSAL_CONFIG);
        myMSALObj.handleRedirectPromise().then(authRedirectCallBack);
    }, []);

    // save logged user in session storage


    const [error, setError] = useState<string>();

    // authentication callback
    const onAuthenticated = async (userAccountInfo: UserDb) => {
        window.setTimeout(() => {
            setShowLoading(false);
            // setUser(userAccountInfo);
            // se arrivo da un link esterno non faccio la redirect sulla home
            let redirectUrl = BaseUrl;
            if (sessionStorage.getItem("RedirectUrl")) {
                redirectUrl = sessionStorage.getItem("RedirectUrl")!;
                sessionStorage.removeItem("RedirectUrl");
            }
            window.location.replace(`${redirectUrl}/`);
        }, 1000)
    };

    const onFailed = () => {
        setShowLoading(false);
        setError("L'utente non ha accesso all'applicazione verrai disconnesso")
        window.setTimeout(() => {
            sessionStorage.clear();
            resetUser();
            window.location.replace(`${BaseUrl}/`);

            const currentUser = myMSALObj.getActiveAccount();
            let logOutRequest: EndSessionRequest = {
                account: currentUser
            };
            myMSALObj.logout(logOutRequest);
        }, 3000)

    }

    const logIn = (method: string): void => {

        const url = new URL(window.location.href);
        const searchParams = new URLSearchParams(url.searchParams);
        const redirectUrl = `${window.location.origin}/login`;
        if (searchParams.get("redirectUrl")) {
            sessionStorage.setItem("RedirectUrl", searchParams.get("redirectUrl")!)

        }

        const loginRedirectRequest = {
            scopes: [],
            prompt: "select_account",
            redirectStartPage: redirectUrl,
            redirectUri: redirectUrl,
            navigateToLoginRequestUrl: false
        };
        console.log('loginRedirectRequest', loginRedirectRequest);

        myMSALObj.loginRedirect(loginRedirectRequest);
    };

    return (
        <>
            <IonPage>
                <IonContent>
                    <IonGrid className="bg-login">
                        <IonRow className="ion-justify-content-center">
                            <IonCol size="4" className="box p-5 ion-align-self-center" >
                                <img className="logo-login mb-3" src={Logo} alt="logo" />
                                <p>Benvenuto in</p>
                                <h3>Site Inspection Report Live</h3>
                                <p>L'applicazione di Prelios Integra per la gestione dei sopralluoghi</p>
                                <IonButton id="authenticationButton" onClick={() => logIn("loginPopup")} >Accedi</IonButton>
                                <IonLoading
                                    cssClass='my-custom-class'
                                    isOpen={showLoading}
                                    onDidDismiss={() => setShowLoading(false)}
                                    message={'Login...'}
                                    duration={5000}
                                />
                                <p style={{ color: "red" }}>{error}</p>
                            </IonCol>
                        </IonRow>
                    </IonGrid>
                </IonContent>
                <Footer />
            </IonPage>
        </>

    );
}

export default Login;